._payee_form_config {
  display: grid;
  grid-template-columns: 600px 1000px;
}
._payee-form {
  @extend ._payee_form_config;
}
._state_zip {
  display: grid;
  grid-template-columns: 100px 10px 100px;
}
._buttons {
  display: grid;
  grid-template-columns: 100px 100px 10px 100px;
}
._payee_bank_info_config {
  display: grid;
  grid-template-columns: 300px 200px 200px 200px;
}
._payee_bank_info_header_color {
  background-color: lightgrey;
}
.container {
  margin-right: 50px;
}
.inputFieldElement {
  padding-top: 10px;
  padding-bottom: 10px;
}
.label {
  height: 57.5px;
  line-height: 57.5px;
  //margin-top: 10px;
  //padding-top: 20px;
  //padding-bottom: 20px;
}
.formButton {
  color:white;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 10px;
  border-radius: 8px;
}
.okButton {
  @extend .formButton;
  background-color: darkblue;
}
.cancelButton {
  @extend .formButton;
  background-color: gray;
}

._achFiles_list_header {
  display: grid;
  grid-template-columns: 50px 300px 50px 200px 250px 200px 200px;
}
._achFilesPayments_list_header {
  display: grid;
  grid-template-columns: 50px 50px 350px 350px 200px 200px 300px;
}
._achFilesPayments_list_header_color {
  background-color: lightgrey;
}

.supplierheader{
    background-color: darkblue;
    color:white;
    text-align: center;
    vertical-align: middle;
    height: 50px;
}
.supplierspan {
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
    padding-top: 15px;
    padding-bottom: 10px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px;
    font-weight: bold;
}
.supplierimage {
    height: 50px;
    padding-left: 20px;
}
.supplierwelcome {
    font-family: Arial, Helvetica, sans-serif;
    margin: 30px 0 0 20px;
}

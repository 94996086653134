._payor_list_config {
  display: grid;
  grid-template-columns: 50px 300px 10px 300px 10px 300px 10px 20px 100px 20px 100px 20px 100px;
}

._users-headers {
  @extend ._payor_list_config;
}

._users-row {
  @extend ._payor_list_config;
}

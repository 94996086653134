._user_form_config {
  display: grid;
  grid-template-columns: 100px 400px 200px 400px 100px 400px;
}
._user-form {
  @extend ._user_form_config;
}
._buttons {
  display: grid;
  grid-template-columns: 100px 100px 10px 100px;
}

._payee_list_search {
  display: grid;
  grid-template-columns: 150px 600px;
  padding-bottom: 10px;
}
._payee_list_config {
  display: grid;
  grid-template-columns: 50px 300px 300px 300px 100px 100px;
}

._payee-headers {
  @extend ._payee_list_config;
}

._payee-row {
  @extend ._payee_list_config;
}
._payee_header_color {
  background-color: lightgrey;
}
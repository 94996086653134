._payor_search {
  border-style: solid;
  width: 50ch;
}
._payor_search_select {
  &:hover {
    background-color:lightgray;
  }
  cursor: pointer;
}

.header{
    text-align: center;
    vertical-align: middle;
    height: 50px;
}
.span {
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
    padding-top: 15px;
    padding-bottom: 10px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px;
    font-weight: bold;
}
.image {
    width: 150px;
    float: left;
    height: 50px;
}
.user {
    float: right;
    height: 50px;
    padding-top: 15px;
    padding-bottom: 10px;
    padding-right: 50px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: larger;
}
/* Navbar container */
.navbar {
    overflow: hidden;
    background-color: blue;
    font-family: Arial;
}

/* Links inside the navbar */
.navbar a {
    float: left;
    font-size: 16px;
    color: white;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
}

/* The dropdown container */
.dropdown {
    float: left;
    overflow: hidden;
}

/* The dropdown container user*/
.dropdown_user {
    float: right;
    overflow: hidden;
}

/* Dropdown button */
.dropdown .dropbtn {
    font-size: 16px;
    border: none;
    outline: none;
    color: white;
    padding: 14px 16px;
    background-color: inherit;
    font-family: inherit; /* Important for vertical align on mobile phones */
    margin: 0; /* Important for vertical align on mobile phones */
}



/* Dropdown content (hidden by default) */
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
    float: none;
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
}

/* Add a grey background color to dropdown links on hover */
.dropdown-content a:hover {
    background-color: #ddd;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
    display: block;
}

.dateSelect {
  display: flex; /* 2. display flex to the rescue */
  flex-direction: row;
}
.dateRangeRangeText {
  width: 150px;
}
.dateRangeToText {
  width: 100px;
  text-align: center;
}

._payor_list_search {
  display: grid;
  grid-template-columns: 150px 600px;
  padding-bottom: 10px;
}

._payor_list_config {
  display: grid;
  grid-template-columns: 50px 350px 150px 350px 150px;
}

._payor-headers {
  @extend ._payor_list_config;
}

._payor-row {
  @extend ._payor_list_config;
}
._payor_header_color {
  background-color: lightgrey;
}
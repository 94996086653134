.container {
    margin-left: 50px;

}
select{
    padding: 20px;
}

.inputFieldElement {
    padding-top: 10px;
    padding-bottom: 10px;
    .form-field{
        label{
            margin-left: 10px;
        }
    }
}


.button {
    background-color: darkblue;
    color:white;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 10px;
    border-radius: 8px;
}
.tandcSpan {
    padding-top: 10px;
    padding-bottom: 20px;
}
.warn::part(form-control-help-text) {
    color: red;
}
.paddedInput {
    padding-top: 2px;
}
